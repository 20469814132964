%atom-hat-default{
    @extend %text-hat;
    text-align: center;
    .container{
        @extend %container;
    }
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    background-color: #f7f7f7;
    padding: 30px 0px;
}