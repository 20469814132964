.not-front{
    @extend %text-basis-rte;
    #loader{
        display: none;
    }
    header{
        .logo-texted{
            opacity: 1;
        }
    }
    .region-post-header{
        @extend %organism-post-header;
    }
    .region-nav-tools{
        @extend %organism-nav-tools;
    }

    .hat{
        @extend %atom-hat-default;
        margin-bottom: 30px;
    }

    #main-content > .container{ 
        @extend %container;
    }


    /*RTE*/
    .rte{
        @extend %organism-rte;
    }

    //  CrossReading
    .block-crossreading{
        @extend %molecule-slider-crossreading;
        margin-bottom: $margin-item-rte;

        &.crossreading-auto{
            @extend %container;
            margin-bottom: $margin-item-rte;

            .owl-nav{
                @include RWD(tablet){
                    width: calc(100% - 80px);                    
                }
                @include RWD(mobile){
                    width: calc(100% - 40px);                     
                }
            }
        }
    }

    /* Structure grilles */
    .grid{
        @include RWD(desktop){
            @include grid-distribution(4, 20, 'grid-item');                
        }
        @include RWD(tablet){
            @include grid-distribution(2, 20, 'grid-item');                
        }
        @include RWD(mobile){
            @include grid-distribution(1, 0, 'grid-item');                
        }
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $core-color-access;
        font-size: 12px;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }

    /* FORM CLIENTS */
    .webform-client-form{
        @extend %container-form;
        // Structure/Distribution
        @extend %bone-form-structure;
        // Design des champs
        @extend %molecule-form;
        // Validation
        @extend %skin-form-validation; 
        margin-bottom: 40px;

        &.preview{
            @extend %molecule-form-preview;
        }
    }
        // Date picker
        #ui-datepicker-div{
            @extend %atom-datepicker;
        }
        // Progress Bar étapes
        .webform-progressbar{
            @extend %molecule-nav-progressbar;
        }
        // Confirmation d'envoi
        .webform-confirmation{
            @extend %molecule-form-confirmation;
        }
        // Messages d'erreurs BO
        .messages.error, .messages.warning{
            @extend %molecule-form-warning;
            margin-bottom: 20px;
        }
        .container{
            .messages.error, .messages.warning{
                @include RWD(desktop){
                    margin-left: -120px;
                    margin-right: -120px;                    
                }
            }
        }

    /* Blocks grilles */
        // Carrefour
        .view-nodehierarchy-children-teasers{
            @extend %organism-page-list;
        }
        // Agenda
        .view-agenda{
            @extend %organism-event-list; 
        }
        // Actus
        .view-actualites{
            @extend %organism-actu-list;
        }
        // Liste géoloc
        .view-maps{
            @extend %organism-map-list;
        }
        // Liste Annuaire
        .view-annuaire{
            @extend %organism-annuaire-list;
        }
        // Recherche
        &.page-search-site{
            @extend %organism-search-list;
            #main-content ul{
                @extend %atom-ul-rte; 
            }
        }



    // Commentaires
    #comments{
        @extend %organism-rte;
        @extend %container-form;
        border-top: solid 1px $core-color;
        padding-top: 30px;
        padding-right: 170px;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        form{
            @extend %molecule-form;
            @extend %bone-form-structure;
        }

        .comment-block-title{
            display: none;
        }

        .comment{
            display: flex;
            flex-wrap: wrap;
            max-width: 580px;
            padding: 20px 15px;
            border: solid 1px #d0d0d0;
            position: relative;
            & > * {
                width: 100%;
            }

            h3{
                order: 2;
            }
            .submitted{
                order: 0;
                color: #d0d0d0;
                font-size: 12px;
                font-size: 1.2rem;
                text-align: right;

                a{
                    display: none;
                }
            }
            .content{
                order: 5;
            }
            .links{
                order: 20;
            }
        }
    }
}