%organism-services-home{
    @extend %bones-section-hp;
    background-color: #fff;

    .grid{
        @include RWD(desktop){
            @include grid-distribution(3,30, 'grid-item');            
        }
        @include RWD(tablet){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .grid-item{
                width: 250px;
                margin-left: 20px;
                margin-right: 20px;
            }
        }
        @include RWD(mobile){
            // .grid-item{
            //     width: calc(50% - 40px);
            // }
        }
        @extend %container;

        .grid-item{
            margin-bottom: 50px;

            a{
                text-decoration: none;
                display: block;

                &:hover, &:focus{
                    .item-visu{ 
                        i, img{
                            transform: scale(1.3);
                        }
                    }
                }
            }

            .item-visu{
                display: flex;
                height: 45px;
                align-items: center;
                justify-content: center;
                margin-bottom: 25px;

                i, img{
                    vertical-align: bottom;
                    max-height: 100%;
                    transition: all, 0.5s; 
                    font-size: 45px;
                }
            }
            .item-title{
                @extend %text-teaser-title;
                color: #000000;
                text-align: center;
                margin-bottom: 25px;
                transition: all, 0.5s;
            }
            .item-description{
                @extend %text-teaser-description;
                color: #5a5a5a;
                text-align: center;
            }

            &:nth-child(odd){
                i{
                    color: $core-color;
                }
                a:hover, a:focus{
                    .item-title{
                        color: $core-color;
                    }
                }
            }
            &:nth-child(even){
                i{
                    color: $second-color;
                }
                a:hover, a:focus{
                    .item-title{
                        color: $second-color;
                    }
                }
            }
        }
    }

    // Animation Scroll
    [data-scroll-animation="grid-delayed"]{
        @for $i from 1 through 6{
            .delayed-element-#{$i}{
                opacity: 0;   
                transform: translateY(80px);
                transition: all, 0.5s;
                transition-delay: #{($i - 1) * 0.2}s;
            }
        }
        &[data-animation-state="active"]{
            .delayed-element{
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
    .btn-section{
        background-color: $core-color;
        margin-top: 0;
        .btn-text{
            color: #FFFFFF;
        }
        &:hover, &:focus{
            background-color: $second-color;
        }
    }
}