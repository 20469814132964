%icon-burger{
    $bar-height: 3px;
    $bar-border-radius: 0;
    $bar-color: #FFFFFF;

    position: relative;
    transform: rotate(0deg);
    transition: all, 0.5s, ease-in-out;
    cursor: pointer;

    span{
        display: block;
        position:  absolute;
        height: $bar-height;
        width: 100%;
        background-color: $bar-color;
        border-radius: $bar-border-radius;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);

        &:nth-child(1){
            top: 0px;
            transition: all, 0.5s, ease-in-out, 0s;
        }
        &:nth-child(2){
            top: 50%;
            margin-top: -#{$bar-height / 2};
            transition: all, 0.5s, ease-in-out, 0.2s;
        }
        &:nth-child(3){
            top: 100%;
            margin-top: -#{$bar-height};
            transition: all, 0.5s, ease-in-out, 0.4s;
        }
    }
    &.opened{
        span{
            left: 350%;
        }
    }
}