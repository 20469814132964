%atom-map-infowindow{
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    padding-top: 80px;

    @include RWD(mobile){
        padding: 20px;
        padding-top: 40px;
    }

    .infowindow-content{
        .gmap-icon{
            margin-bottom: 25px;
            text-align: center;
            svg{
                height: 35px;
                path{
                    fill: $second-color;
                }
            }
            @include RWD(mobile){
                display: none;
            }
        }
        .gmap-title{
            @extend %atom-h3-rte;
            margin-bottom: 25px;
            padding-left: 0 !important;
        }
        .gmap-loc{
            strong{
                display: block;
            }
        }
        .gmap-description{
            display: none;
        }
    }
    .gmap-close{
        @extend %icon-close;
        cursor: pointer; 
        position: absolute;
        top: 40px;
        right: 40px;
        @include RWD(mobile){
            top: 20px;
            right: 20px;
        }

        &:hover{
            &:after, &:before{
                background-color: #2D2D2D;
            }
        }
    }
} 