%organism-quicklinks-home{
    @extend %bones-section-hp;
    background-color: $second-color;
    .subtitle{
        color: #FFFFFF;
    }
    .title{
        color: #FFFFFF;
    }

    .grid{
        @include RWD(desktop){
            @include grid-distribution(4,55, 'grid-item');            
        }
        @include RWD(tablet){
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .grid-item{
                width: 180px;
                margin-left: 20px;
                margin-right: 20px;
            }
        }
        @include RWD(mobile){
            .grid-item{
                width: calc(50% - 10px);
                margin-left: 10px;
                margin-right: 10px;
                margin-bottom: 25px;

                &:nth-child(odd){
                    margin-left: 0;
                }
                &:nth-child(even){
                    margin-right: 0;
                }
            }
        }
        @extend %container;

        .grid-item{
            margin-bottom: 50px;

            a{
                text-decoration: none;
                display: block;

                &:hover, &:focus{
                    .item-visu{
                        i, img{
                            transform: scale(1.3);
                        }
                    }
                }
            }

            .item-visu{
                display: flex;
                height: 45px; 
                align-items: center;
                justify-content: center;
                margin-bottom: 25px;
                
                i, img{
                    vertical-align: bottom;
                    max-height: 100%;
                    transition: all, 0.5s;
                    color: #FFFFFF;
                    font-size: 45px; 
                    font-size: 4.5rem; 
                }
            }
            .item-title{
                @extend %text-teaser-title;
                color: #FFFFFF;
                text-align: center;
                margin-bottom: 25px;
            }
        }
    }
    // Animation Scroll
    [data-scroll-animation="grid-delayed"]{
        @for $i from 1 through 12{
            .delayed-element-#{$i}{
                opacity: 0;  
                transform: translateY(80px);
                transition: all, 0.5s;
                transition-delay: #{($i - 1) * 0.2}s;
            }
        }
        &[data-animation-state="active"]{
            .delayed-element{
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}