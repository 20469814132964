%atom-btn-default{
    padding: 30px;
    background-color: #2D2D2D;
    color: #FFFFFF;
    transition: all, 0.5s;
    cursor: pointer;

    &:hover, &:focus{
        background-color: #fff;
        color: #2D2D2D;
    }
}

%atom-btn-hp{
    padding: 0px 28px;
    height: 50px;
    display: inline-flex;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    .btn-text{  
        font-family: $site-font;
        transition: all, 0.5s;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        font-size: 1.6rem;
    }
    .flexbox{
        width: 100%;
        z-index: 1;
    }

    &:before{
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        width: 100%;
        content: '';
        display: block;
        transition: all, 0.3s;
    }
}
%atom-btn-int{
    @extend %atom-btn-hp;   

    .btn-text{
        position: relative;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 16px;
        font-size: 1.6rem;
    }
    &:hover, &:focus{
        &:before{
            height: 100%;
        }
    }

    &--blue{
        @extend %atom-btn-int;
        background-color: $second-color;
        &:before{
            background-color: $core-color;
        }
    }
    &--green{
        @extend %atom-btn-int;
        background-color: $core-color;
        &:before{
            background-color: $second-color;
        }
    }
    &--white{
        @extend %atom-btn-int;
        background-color: #FFFFFF;
        .btn-text{
            color: #222222;
        }
        &:before{
            background-color: $second-color;
        }
        &:hover, &:focus{
            .btn-text{
                color: #FFFFFF;
            }
        }
    } 
    &--light-grey{ 
        @extend %atom-btn-int;
        background-color: #f7f7f7; 
        .btn-text{
            color: #222222;
        }
        &:before{
            background-color: $second-color;
        }
        &:hover, &:focus{
            .btn-text{
                color: #f7f7f7;
            }
        }
    }
}
%atom-btn-scroll-top{
    bottom: 20px;
    position: fixed;
    font-size: 20px;
    right: 25px;
    z-index: 800;
    background: $core-color;
    color: #fff;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    transition: al, 0.5s;

    &:hover, &:focus{
        background-color: $second-color;
    }
}