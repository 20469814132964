%molecule-user-alert{
    background-color: $second-color;
    position: relative;

    & > * {
        color: #FFFFFF;
    }

    .user-label{
        margin-right: 10px;
        text-transform: uppercase;
        display: flex;
        align-items: center;
    }
    .user-alert-message {
        font-size: 1.5rem;
        display: flex;
    }
    .user-alert-body{

    }
    .close{
        transition: all, 0.5s;
        .circle{
            fill: #FFFFFF;
        }
        .cross{
            fill: $second-color;
        }
        &:hover, &:focus{
            opacity: 0.5;
            .circle{
                fill: #FFFFFF;
            }
            .cross{
                fill: $second-color;
            }
        }
    }
}