%organism-banner-home{
    height: 100vh;
    max-height: 1080px;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;

    @include RWD(mobile){
        height: 70vh;
    }

    &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        // background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.02) 9%,rgba(0,0,0,0.09) 25%,rgba(0,0,0,0.41) 76%,rgba(0,0,0,0.5) 100%);
        background: rgba(0,0,0,0.2);
        z-index: 1;
        transition: all, 0.5s;
        opacity: 1;
    }

    .banner-animated{
        z-index: 2;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 70px); 
        opacity: 0; 
        transition: all, 0.5s;
        text-decoration: none;

        @include RWD(mobile){
            width: 80%;
            text-align: center;
        }

        &[data-animation-state="active"]{
            opacity: 1;
            transform: translate(-50%, 0);
        } 
    }

    .banner-title{
        font-weight: 700;
        font-size: 50px;
        font-size: 5.0rem;
        letter-spacing: 0.2em;
        line-height: 1.2em;
        text-transform: uppercase;
        color: #FFFFFF;

        padding: 20px 30px;
        max-width: 670px;
        text-align: center;
        transition: all, 0.5s;

        &:before{
            opacity: 0;
            content: "";
            position: absolute;
            display: inline-block;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: -1;
            background: rgba(0,0,0,0);
            transition: all, 0.5s;
            transform: scale(1.08);
        }

        @include RWD(mobile){
            // max-width: calc(100% - 40px);
            font-size: 20px;
            font-size: 2.0rem;
            display: inline-block;
            max-width: auto;
        }
    }
    .banner-btn{
        @extend %atom-btn-hp;
        padding: 15px 20px;
        background-color: $core-color;
        transition: all, 0.5s;
        position: absolute;
        bottom: -35px;
        right: -300px;
        opacity: 0;
        text-decoration: none;
        color: #FFFFFF;

        &:hover{
            background-color: $second-color;
        }
    }

    &.animated{
        &:before{
            opacity: 0;
        }
        .banner-title{
            &:before{
                transform: scale(1);
                opacity: 1;
                background: rgba(0,0,0,0.7);
            }
        }
        .banner-btn{
            right: 20px;
            opacity: 1;
        }
    }

    video, iframe{
        position: absolute;
        top: 50%;
        left: 50%; 
        transform: translate(-50%, -50%);
    }
    iframe{
        height: 100%;
        width: 100%;
    }
}