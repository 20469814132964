%molecule-loader{
    position: fixed;
    z-index: 999999999;
    background-color: #FFFFFF;
    height: 100%;
    width: 100%;

    .bar{
        position: absolute;
        top: 45%;
        left: 0;
        bottom: auto; 
        margin-top: 50px;
        background-color: $core-color;
        height: 10px;
        width: 0; 

        &.animate{
            transition: all, 0.5s;
            margin-top: 0;
            top: 0;
            height: 100%; 
        }
    }
    .loading-count{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 500;
        color: #000000;
        font-size: 40px;
        font-size: 4.0rem;
    }
}