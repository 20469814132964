%molecule-form-warning{
    border: none;
    padding: 20px 20px 20px 85px;
    color: #9a9a9a;
    background: none; 
    background-color: #f1f1f1;
    position: relative;

    &:before{
        position: absolute;
        left: 20px;
        top: 20px;
        content: '';
        height: 45px;
        width: 45px;
        display: block;
        background-position: center;
        background-size: auto 55px;
        background-repeat: no-repeat;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgZmlsbD0iIzYwODAwMCIgd2lkdGg9IjEwMHB4IiBoZWlnaHQ9IjEwMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+DQo8Zz4NCgk8cGF0aCBkPSJNNDUuNzY0LDY4LjExNGMwLDIuNDQ4LDEuOTg0LDQuNDMzLDQuNDM0LDQuNDMzczQuNDM0LTEuOTg0LDQuNDM0LTQuNDMzYzAtMi40NTEtMS45ODQtNC40MzUtNC40MzQtNC40MzUNCgkJUzQ1Ljc2NCw2NS42NjMsNDUuNzY0LDY4LjExNHoiLz4NCgk8cGF0aCBkPSJNNTIuNSw1NC44ODF2LTIzYzAtMS4xMDQtMC44OTYtMi0yLTJzLTIsMC44OTYtMiwydjIzYzAsMS4xMDQsMC44OTYsMiwyLDJTNTIuNSw1NS45ODUsNTIuNSw1NC44ODF6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
        border: solid 2px $core-color;
        border-radius: 50%;
    }

    h2.element-invisible{
        display: block;
        font-size: 20px;
        font-size: 2.0rem;
        color: #7e8c8d;
        margin-bottom: 20px;
        font-weight: 500;
    }
}