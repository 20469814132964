/**
   Animation Slider: Les flèches sont cachées de base, au survol, elles apparaissent en slide-in
*/

@mixin animationArrowSliderMoveIn($out-of-screen, $in-screen){
    .owl-nav{

        div.owl-prev{
            left: $out-of-screen;
            opacity: 0;
        }
        div.owl-next{
            right: $out-of-screen;
            opacity: 0;
        }

    }

    &:hover, &:focus{
        .owl-nav{

            div.owl-prev{
                left: $in-screen;
                opacity: 1;
            }
            div.owl-next{
                right: $in-screen;
                opacity: 1;
            }

        }
    }
} 

/**
    Animation slider int: de base les numéros de page du slider sont cachés, au survol des flèches, les numéros de page fadent in
*/
%animation-slider-arrow-fade{
    .owl-pagination{
        opacity: 0;
        transition: all, 0.5s;
    }
    &:hover, &:focus{
        .owl-pagination{
            opacity: 1;
        }
    }
}