%molecule-form-confirmation{
    @extend %container-form;
    background-color: #f4f4f4;
    padding: 60px 40px;

    @include RWD(mobile){
        padding: 20px;
    }

    .confirm-title{
        @extend %text-h3;
        text-align: center;
        &:before{
            background-image: url('data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FscXVlXzEiIGRhdGEtbmFtZT0iQ2FscXVlIDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDE1NCAxMjAuNjciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS1taXRlcmxpbWl0OjEwO3N0cm9rZS13aWR0aDoxM3B4O308L3N0eWxlPjwvZGVmcz48dGl0bGU+UGxhbiBkZSB0cmF2YWlsIDE8L3RpdGxlPjxwb2x5bGluZSBjbGFzcz0iY2xzLTEiIHBvaW50cz0iMTQ5LjUgNS4zMyA0NSAxMTAuNzMgNSA3Mi4yMyIvPjwvc3ZnPg==');
            background-position: center;
            background-repeat: no-repeat;
            content: '';
            height: 83px;
            width: 83px;
            background-color: $core-color;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 40px auto;
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 25px;
        }

        & + p{
            margin-top: 20px;
            text-align: center;
        }
    }

    & > * {
        margin: 0 auto;
    }

    &+ .links{
        @extend %container-form;
        margin-top: 20px;
        a{
            @extend %atom-btn-int--blue;
        }
        margin-bottom: 20px;
    }
}