%icon-close-2{
    cursor: pointer;
    &:after, &:before{
        display: inline-block;
        content: '';
        width: 30px;
        height: 3px;
        background-color: #000; 
        position: absolute;
        top: 13%;
        left: 0%;
        transition: all, 0.5s, ease-in-out;
    }
    // &:after{ 
    //     transform: rotate(-45deg);
    // }
    // &:before{
    //     transform: rotate(45deg);
    // }
}
%icon-close{
    position: relative;
    height: 16px;
    width: 16px;  

    &:after, &:before{
        display: inline-block;
        content: '';
        height: 20px;
        width: 2px;
        background-color: #c8c8c8;
        position: absolute;
        top: -11%;
        left: 45%;
        transition: all, 0.5s;
    }
    &:after{ 
        transform: rotate(-45deg);
    }
    &:before{
        transform: rotate(45deg);
    }
}