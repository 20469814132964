// IE 11 only
_:-ms-fullscreen, :root{ 
    header #search-engine .flexbox .close-search{
        overflow: visible;
    }
    header #main-menu a{
        transform: none !important;
        &:hover, &:focus{
            transform: none !important;
        }
    }
    .page-navigation404 #main-content .link-group .link-group-item svg, .not-front .rte .link-group .link-group-item svg, .not-front #comments .link-group .link-group-item svg, .page-sitemap #site-map .link-group .link-group-item svg{
        height: 30px;
    }
    .not-front #ui-datepicker-div select{
        background: none;
        padding: 0;
    }
    .not-front .view-actualites .view-filters .webform-layout-box, .not-front .view-agenda .view-filters .webform-layout-box, .not-front .view-maps .view-filters .webform-layout-box, .not-front.page-search-site #search-form .webform-layout-box, .not-front .webform-client-form .webform-layout-box, .not-front #comments form .webform-layout-box{
        & > .form-group{
            flex: 1;
        }
    }
}