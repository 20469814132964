%organism-footer {
	overflow: hidden;
	width: 100%;

	.region-footer-top {
		overflow: hidden;
		display: flex;
		width: 100%;
		flex-wrap: wrap;

		@include RWD(tablet) {
			flex-wrap: wrap;

			& > * {
				width: 100%;
			}
		}

		& > * {
			width: 50%;
		}
	}

	// Newsletter
	.block-mailchimp-signup {
		width: 100%;
		padding: 30px 0;
		background-color: $core-color;

		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include RWD(mobile) {
				display: block;
			}

			h2 {
				max-width: 320px;
				color: #FFFFFF;
				@extend %text-section-title-hp;

				@include RWD(tablet) {
					font-size: 25px;
					font-size: 2.5rem;
				}

				@include RWD(mobile) {
					max-width: 100%;
					margin-bottom: 20px;
				}
			}

			form {
				// flex: 1; 
			}

			form > div {
				display: flex;
				width: auto;
				margin: unset;

				@include RWD(tablet) {
					flex: 1;
				}

				@include RWD(mobile) {
					display: block;
				}

				// Validation
				@extend %skin-form-validation;

				.mailchimp-newsletter-mergefields {
					width: 510px;

					@include RWD(tablet) {
						flex: 1;
						width: unset;
					}
				}

				input {
					height: 45px;
					@extend %atom-form-input;
				}

				.form-actions {
					@include RWD(mobile) {
						display: flex;
						justify-content: flex-end;

						button {
							margin-top: 20px;
							margin-left: 0;
						}
					}

					button {
						height: 45px;
						margin-left: 20px;
						@extend %atom-btn-int--white;
					}
				}

				.form-label {
					display: none;
				}
			}
		}
	}

	// Infos
	#infos {
		padding: 90px 10%;
		border-top: solid 2px #F5F5F5;
		@extend %bones-section-hp;

		@include RWD(tablet) {
			padding-left: 15vw;
			padding-right: 15vw;
		}

		@include RWD(mobile) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include RWD(mobile) {
			padding-bottom: 45px;
		}

		.subtitle {
			color: #333333;
		}

		.title {
			margin-bottom: 75px;
			color: #222222;
		}
	}

	.infos-part {
		margin-bottom: 40px;
		text-align: center;

		h3 {
			margin-bottom: 5px;
			font-size: 16px;
			font-size: 1.6rem;
			font-weight: 500;
			color: #000000;
		}

		p {
			color: #5a5a5a;
			@extend %text-teaser-description;
		}

		a {
			color: $core-color;
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	.infos-socials {
		display: flex;
		justify-content: center;

		a {
			overflow: hidden;
			position: relative;
			position: relative;
			display: inline-flex;
			justify-content: center;
			height: 44px;
			width: 44px;
			align-items: center;
			font-size: 20px;
			color: #000 !important;
			transition: all, 0.5s;
			border: 1px solid #000;
			border-radius: 44px;
			text-decoration: none;

			i:before {
				position: relative;
				transition: all, 0.5s;
			}

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: inline-block;
				height: 0;
				width: 0;
				background-color: $core-color;
				transform: translate(-50%, -50%);
				transition: all, 0.5s;
				border-radius: 50000px;
			}

			&:hover,
			&:focus {
				border-color: $core-color;

				&:before {
					height: 110%;
					width: 110%;
				}

				i:before {
					color: #FFFFFF;
				}
			}
		}

		li + li {
			margin-left: 5px;
		}
	}

	// Contact 
	#contact {
		padding: 90px 10%;
		background-color: #f5f5f5;
		@extend %bones-section-hp;

		@include RWD(tablet) {
			padding-left: 15vw;
			padding-right: 15vw;
		}

		@include RWD(mobile) {
			padding-top: 45px;
			padding-left: 20px;
			padding-right: 20px;
		}

		.title {
			margin: 20px 0;
			color: #222222;
		}

		a {
			width: 100%;
			background: #608000;
            text-align: center;
            margin: 20px 0 !important;
		}
	}

	// Animation footer top
	[data-scroll-animation="curtain-close"] {
		#infos {
			transform: translateX(-100%);
			opacity: 0;
			transition: all, 0.8s;
			transition-delay: 0.5s;
		}

		#contact {
			transform: translateX(100%);
			opacity: 0;
			transition: all, 0.8s;
			transition-delay: 0.5s;
		}

		&[data-animation-state="active"] {

			#infos,
			#contact {
				transform: translateX(0);
				opacity: 1;
			}
		}
	}

	// Footer Bottom
	.region-footer-bottom {
		padding: 35px 0;
		background-color: #121416;

		#menu-main-footer-menu {
			display: flex;
			justify-content: center;

			@include RWD(mobile) {
				flex-wrap: wrap;

				li {
					margin-bottom: 10px;
				}
			}


			li + li {
				margin-left: 20px;
			}

			a {
				font-size: 16px;
				font-size: 1.6rem;
				color: #FFFFFF;
				transition: color, 0.5s;
				text-decoration: none;

				&:hover,
				&:focus {
					color: $core-color;
				}
			}
		}

		#menu-footer-menu {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			margin-top: 25px;

			@include RWD(mobile) {
				flex-wrap: wrap;

				li {
					margin-bottom: 10px;
				}
			}

			li + li {
				margin-left: 15px;
			}

			a {
				font-size: 14px;
				font-size: 1.4rem;
				color: #a6a39f;
				transition: color, 0.5s;
				text-decoration: none;

				svg {
					#TELMEDIA > path {
						transition: fill, 0.5s;
					}
				}

				&:hover,
				&:focus {
					color: $core-color;

					svg {
						#TELMEDIA > path {
							fill: $core-color;
						}
					}
				}
			}
		}
	}

}