%molecule-main-search{
    padding: 0 20px;

    .form-group{
        display: flex;
        align-items: center;
    }

    input[type="text"]{
        @extend %atom-form-input-contact;
        background-color: transparent;
        border: none;
        height: auto;
        color: #FFFFFF;
        padding: 0;
        letter-spacing: 0.05em;
        font-size: 50px;
        font-size: 5.0rem;
        font-weight: 400;
        font-family: $site-font;

        &::-webkit-input-placeholder {
            color: white;
        }
        &:-moz-placeholder{ 
            color: white;
        } 
        &::-moz-placeholder { 
            color: white;
        }
        &:-ms-input-placeholder {  
            color: white;
        }

        @include RWD(mobile){
            font-size: 25px;
            font-size: 2.5rem;
        }
    }

    .search-button{
        cursor: pointer;
        i{
            color: #FFFFFF;
            font-size: 50px;
            font-size: 5.0rem;

            @include RWD(mobile){
                font-size: 25px;
                font-size: 2.5rem;
            }
        }
    }
}