%organism-agenda-home{
    @extend %bones-section-hp;
    padding-bottom: 0; 
    background-color: #f8f8f8;

    .subtitle{
        color: #222222; 
    }
    .title{
        color: #222222;
    }

    .agenda-filters{
        @extend %container;
        display: flex;
        justify-content: center;

        li{
            margin-bottom: 10px;
         
        }
        li + li {
            margin-left: 10px;
        }
        a{
            @extend %atom-btn-hp;
            border: solid 1px #909090;
            .flexbox{
                z-index: 1;
            }
            .btn-text{
                color: #5a5a5a;
                font-weight: 500;
                z-index: 1;
            }
            &:before{
                background-color: $core-color;
            }

            &:hover, &:focus{
                .btn-text{
                    color: #FFFFFF;
                }
                &:before{
                    height: 100%;
                }
            }

           
        }
        @include RWD(tablet){
            flex-wrap: wrap;
            justify-content: space-around;

            li + li {
                margin-left: 0;
            }
        }
         @include RWD(mobile){
             li{
                @include RWD(mobile){                
                    width: calc(50% - 5px);
                }
             }
             a{
                // padding: 12px 22px;
                padding: 12px 0;
                width: 100%;
                .flexbox{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }

                .btn-text{
                    font-size: 12px;
                    font-size: 1.2rem;
                }
            }
        }
    }

    .agenda-masonry{
        margin-top: 30px;
        display: flex;
        @include RWD(tablet){
            flex-wrap: wrap;
        }
        & > * {
            width: 100%;
            @include RWD(desktop){
                width: 50%;                
            }
        }
        .left-wall{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .card{
                &:nth-child(1){
                    width: 100%;
                }
                &:nth-child(2),
                &:nth-child(3){
                    width: 50%;
                }
            }
        }
        .right-wall{
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;

            .card{
                &:nth-child(3){
                    width: 100%;
                }
                &:nth-child(1),
                &:nth-child(2){
                    width: 50%;
                }
                @include RWD(tablet){
                    &:nth-child(3){
                        order: 1;
                    }
                    &:nth-child(2){
                        order: 3;
                    }
                    &:nth-child(1){
                        order: 2;
                    }
                }
            }
        }
        .card{
            height: 17vw;
            width: 100%;
            overflow: hidden;
            position: relative;
            
            a{
                display: block;
                height: 100%;
                &:hover, &:focus{
                    .card-visu{
                        transform: scale(1.3); 
                    }
                    .card-overlay{
                        top: 10px; left: 10px; bottom: 10px; right: 10px;

                        .card-subtitle{
                            opacity: 1;
                            height: auto;
                        }
                    }
                }
            }
            .card-visu{
                // position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                transition: all, 0.5s;
                // max-width: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                height: 100%;
                
                .card-background{
                    height: 100%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            .card-date-box{
                position: absolute;
                top: 0;
                left: 0;
                background-color: $core-color;
                z-index: 2;
                display: flex;
                .card-date-item{
                    padding: 18px 20px;

                    & + .card-date-item{
                        &:before{
                            content: '';
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            content: '\f054';
                            font: normal normal normal 14px/1 FontAwesome;
                            font-size: 20px;
                            color: #FFFFFF;
                        }
                    }
                }
                .day{
                    font-weight: 600;
                    font-size: 24px;
                    font-size: 2.4rem;
                    display: block;
                    color: #FFFFFF;
                    margin-bottom: 5px;
                }
                .month{
                    color: #FFFFFF;
                    display: block;
                    font-weight: 500;
                    font-size: 16px;
                    font-size: 1.6rem;
                }
            }
            .card-overlay{
                position: absolute;
                // top: 10px; left: 10px; bottom: 10px; right: 10px;
                bottom: 0;
                top: calc(100% - 38px);
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(255,255,255,.8);
                width: auto;
                height: inherit;
                color: #222222;
                // opacity: 0;
                filter: alpha(opacity=0);
                padding: 2%;
                z-index: 1;
                transition: all, 0.5s;
                z-index: 3;
                height: auto;
                min-height: 35px;
 
                left: 0;
                right: 0;
            }
            .card-content{
                text-align: center;
                width: 100%;
            }
            .card-title{
                @extend %text-teaser-title;
                color: $core-color;  
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;              
            }
            .card-subtitle{
                @extend %text-teaser-description; 
                font-size: 14px;
                font-size: 1.4rem; 
                opacity: 0;
                height: 0;
            }
            // hr{
            //     color: #000;
            //     width: 0;
            //     transition: all, 0.5s;
            //     margin: 15px auto;
            // }
            &.big{
                height: 34vw;
            }
            @include RWD(tablet){
                height: 34vw;
                &.big{
                    height: 51vw;
                }
            }
        }

        @include RWD(mobile){
            .card{
                width: 100% !important;
                height: 68vw;

                &.big{
                    width: 100%;
                    height: 68vw;
                }
            }
        }
    }
    // Animation Masonry
    [data-scroll-animation="topFadeIn"]{
        opacity: 0;
        transform: translateY(100px);
        transition: all, 0.8s;
        transition-delay: 0.2s;

        &[data-animation-state="active"]{
            opacity: 1;
            transform: translateY(0);
        }
    }
}