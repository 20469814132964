%organism-push-home{
    @extend %bones-section-hp;
    background-color: #fff;

    .grid-push{
        @extend %container;
        @include RWD(desktop){
            @include grid-distribution(3,30, 'grid-item');            
        }
        @include RWD(tablet_only){
            @include grid-distribution(3,30, 'grid-item');
        }
        .grid-item{
            // margin-bottom: 50px;

            @include RWD(mobile){
                margin-bottom: 40px;
            }

            a{
                text-decoration: none;
                display: block;

                &:hover, &:focus{
                    .item-background{
                        transform: scale(1.2);
                    }
                    .item-visu img{
                        transform: translate(-50%, -50%) scale(1.2);
                    }
                }
            }

            .item-visu{
                height: 360px;
                position: relative;
                overflow: hidden;

                & > * {
                    // position: absolute;
                    // top: 50%; left: 50%;
                    // transform: translate(-50%, -50%);
                    // transition: transform, 0.5s;
                }
                @include RWD(tablet){
                    height: 23vw;
                }
                @include RWD(mobile){
                    height: 69vw;
                }

                .item-background{
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    height: 100%;
                    transition: all, 0.5s;
                    transform: scale(1);
                }
            }
            .item-title{
                @extend %text-teaser-title;
                color: #000000;
                text-align: center;
                margin-top: 15px;
            }
            .item-description{
                @extend %text-teaser-description;
                color: #5a5a5a;
                text-align: center;
                margin-top: 20px;
            }
        }
    }
    // Animation Scroll
    [data-scroll-animation="grid-delayed"]{
        @for $i from 1 through 3{
            .delayed-element-#{$i}{
                opacity: 0;  
                transform: translateY(80px);
                transition: all, 0.5s;
                transition-delay: #{($i - 1) * 0.2}s;
            }
        }
        &[data-animation-state="active"]{
            .delayed-element{
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}