#mailchimp-signup-subscribe-page-inscription-newsletter-form {
  width: 80%;
  margin: 50px auto;
  margin-top: 50px;

  &:before {
    content: "S'inscrire à notre newsletter";
    margin-bottom: 1em;
    font-size: 32px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }

  input {
    height: 45px;
    width: 100%;
    padding: 15px;
    background-color: #fff;
    font-size: 14px;
    color: #4c4c4c;
    border: 1px solid #d0d0d0;
    border-radius: 0;
  }

  .form-label {
    display: flex;
    justify-content: flex-start;
    width: 100;
    align-items: center;
    margin-bottom: 10px;

    label {
      display: block;
      margin-bottom: 7px;
      font-size: 16px;
      font-weight: 500;
      color: #222222;
    }
  }

  button {
    height: 100%;
    height: 45px;
    width: 100%;
    margin-top: 33px;
    margin-left: 20px;
    background-color: #608000;
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
  }

  .mpc-2 {
    display: flex;
    margin-top: 30px;
  }
}
