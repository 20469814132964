%molecule-media{
    clear: both;
    .media-body{
        padding: 20px;
        background-color: #f7f7f7;
    }
    .media-content{
        display: block;
    }
    .media-ratio{
        margin-bottom: 40px;
        width: 100%;
    }
    .media-actions{
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }
    .media-heading{
        padding-left: 0;
        // Extend text media heading
    }
    .media-description{
        @extend %text-basis-rte;
        padding: 0 20px;
        & > *:last-child{
            margin-bottom: 0;
        } 
    }

    .media-transcription-button{
        @extend %atom-btn-int--blue;
    }
    .media-transcription{
        @extend %molecule-transcription-general;
    }
}