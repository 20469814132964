// .ios, .ipad, .iphone, .ipod

// If input/select/textarea inside Fixed element /!\ conflict with edge: prevent page scrolling
// html, body{
// 	// -webkit-overflow-scrolling : touch !important;
//     overflow: auto !important;
//     // height: 100% !important;
// }

html.ios{
    header .search-trigger i{
        margin-left: 2px;
    }
} 

