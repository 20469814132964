%organism-header-home{
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 10500;
    transition: all, 0.5s;

  .btn-logout-intranet {
    position: fixed;
    right: 20px;
    padding: 20px;
    background-color: $core-color;
    top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: #fff;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }

    .btn-arrow{
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url( svg-bg-uri($svg-map-chevron, #fff) );
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .main-logo img {
    height: 90px;

    @at-root {
      .not-front & {
        height: 65px;
        margin-top: -30px;

        @include RWD(mobile) {
          height: 50px;
          margin-top: -10px;
        }
      }
    }
  }

  .header__fb, .header__insta, .header__linkedin {
    margin-right: 30px;
    color: #fff;
    font-size: 2.8rem;
  }

  .header__linkedin {
    width: 25px;
    height: 25px;
    background-image: url( svg-bg-uri($svg-linkedin, 'white') );
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }



    &.fixed{
        background-color: #fff;
        box-shadow: 0px 10px 27px -17px rgba(0, 0, 0, 0.2);

      .header__fb, .header__insta {
        color: #000;
      }

      .header__linkedin {
        background-image: url( svg-bg-uri($svg-linkedin, 'black') );
      }

        .container{
            padding-top: 18px;
            padding-bottom: 18px;
        }
        .logo-texted{
            .top {
              display: none;
            }

          .fixe {
            display: block;
          }

          .header__fb {
            color: #000;
          }
        }
        .menu-trigger{
            span{
                background-color: #222222;
            }
        }
        .search-trigger{
            i{
                color: #222222;
            }
        }
    }
    .container{
        display: flex;
        justify-content: space-between;
        transition: all, 0.5s;
        padding-top: 35px;
        padding-bottom: 35px;

        @include RWD(mobile){
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    .main-logo{
        text-decoration: none;
    }
    .logo-texted{
        font-size: 23px;
        font-family: $site-font;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 30px;
        font-size: 3.0rem;
        letter-spacing: 0.2em;
        transition: all, 0.5s;

      .fixe {
        display: none;
      }

        @include RWD(mobile){
            font-size: 21px ;
            font-size: 1.6rem ;
            letter-spacing: 0.15em;
            max-width: 50%;
            height: 60px;

            img {
              height: 100% !important;
            }
        }
    }
    .nav-right{
        display: flex;
        align-items: center;
    }
    .search-trigger{
        margin-right: 30px;
        cursor: pointer;
        transition: all, 0.5;
        i{
            color: #FFFFFF;
            font-size: 25px;
            font-size: 2.5rem;
        }

        @include RWD(mobile){
            margin-right: 15px;
        }
    }
    #search-engine{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: none;
        z-index: 5000;

        .element-invisible{
            display: none;
        }
        .form-label{
            display: none;
        }

        &.opened{
            .flexbox{
                transform: translateY(0);
            }
        }
        .flexbox{
            height: 100%;
            width: 100%;
            background-color: rgba($core-color , 0.9);
            z-index: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all, 0.5s;
            transform: translateY(200px);

            .close-search{
                position: absolute;
                height: 25px;
                width: 30px;
                right: 50px;
                top: 50px;
                @extend %icon-close-2;
                cursor: pointer;
                &:after{
                    transform: rotate(-45deg);
                    background-color: #FFFFFF;
                }
                &:before{
                    transform: rotate(45deg);
                    background-color: #FFFFFF;
                }
                @include RWD(mobile){
                    right: 20px;
                    top: 20px;
                }
            }
            form{
                @extend %molecule-main-search;

                & > div{
                    display: flex;
                }
                #edit-actions{
                    display: flex;
                    align-items: center;
                }
                #edit-submit{
                    cursor: pointer;

                    .btn-text{
                        display: none;
                    }
                    .flexbox{
                        background: transparent;
                    }
                    .btn-arrow{
                        display: inline-block;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        color: #FFFFFF;
                        font-size: 50px;
                        font-size: 5.0rem;

                        &:before{
                            content: "\f002";
                        }
                    }
                }
            }
        }
    }
    .menu-trigger{
        width: 29px;
        height: 23px;
        @extend %icon-burger;
        transition: all, 0.5s;
        @include animation-topFadeIn(70px);
        @include RWD(mobile){
            width: 26px;
            height: 19px;
        }
    }

    #main-menu{
        padding-top: 20px;
        transition: all, 0.5s, ease-in-out, 0.8s;
        transform: translateX(50%);
        overflow: hidden;

        & > .menu .menu{
            display: none;
        }
        .submenu{
            display: none;
        }

        li{
            margin-bottom: 3vh;
        }
        i{
            color: #43b8b4;
            font-size: 2vh;
            margin-right: 1.5vh;

        }
        a{
            font-family: $site-font;
            font-weight: 700;
            font-size: 30px;
            font-size: 3.0rem;
            font-size: 2.6vh;
            color: #eef8f8;
            text-decoration: none;
            display: flex;
            align-items: center;
            transform: translateX(-3.5vh);
            transition: all, 0.5s;

            @include RWD(desktop){
                &:hover, &:focus{
                    transform: translateX(0);
                }
            }
        }
    }

    .menu-close{
        position: absolute;
        right: 50%;
        top: 35px;
        right: 30px;
        height: 23px;
        width: 29px;
        transition: all, 0.5s;
        opacity: 0;
        @extend %icon-close-2;
        transform: translateX(-17.5vw);
        opacity: 0;

        &:after{
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
        &:before{
            transform: rotate(45deg);
            background-color: #FFFFFF;
        }
    }
    .side-panel{
        position: fixed;
        height: 100%;
        top: 0;
        width: 25vw;
        right: 0px;
        overflow: auto;
        background-color: $second-color;
        transition: all 500ms cubic-bezier(0.600, 0.300, 0.000, 1.000);
        transform: translateX(100%);
        z-index: 10000;
        padding: 7.38vh 2.85vw;

        @include RWD(mobile){
            padding: 60px 20px;
        }
        &.opened{
            transform: translateX(0);
            box-shadow: -11px 0px 18px -8px rgba(0,0,0,0.3);
            #main-menu{
                transform: translateX(0);
            }
            .menu-close{
                opacity: 1;
                transform: translateX(0);
                overflow: visible;

                &:hover, &:focus{
                    opacity: 0.7;
                }
            }
            @include RWD(tablet){
                width: 300px;
            }
            @include RWD(mobile){
                width: 250px;
            }
        }
    }
}
