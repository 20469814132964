%atom-form-input{
    width: 100%;
    height: 35px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 0;
    color: #4c4c4c;
    font-size: 14px;
    padding: 0 15px; 

    &:focus{
        border-color: $core-color;
    }
} 

%atom-form-input-contact{
    width: 100%;
    height: 45px;
    background-color: #fff;
    border: 1px solid #d0d0d0;
    border-radius: 0;
    color: #4c4c4c;
    font-size: 14px;
    padding: 15px; 

    &:focus{
        border-color: $core-color;
    }
} 