%molecule-slider--full {
	position: relative;
    height: 100vh;
    
    .container-content-slide{
        width: 100%;
        display: block;
        margin-left: auto;
        height: 100%;
        margin-right: auto;
        position: absolute;
        top: 0;
    }
    
    .container--flex{
        max-width: 1100px;
        position: relative;
        height: 100%;
        margin: 0 auto;
        display: flex;
    }

	// Activation du mode background-cover
	@extend %bones-slider-cover-ratio--full;

	// Animation arrow prev next fadein au hover du slider
	@include animationArrowSliderMoveIn(0, 40px);

	// FULL HEIGHT => .owl-item: 100vh + le slider en position absolute, top/bottom/left/right 0 + pager en position absolute;

	// Taille et couleur des slides
	.owl-item {
		backface-visibility: visible !important; // Fix le flicker sur les images à cause de translate 3D
		height: 100vh;
		background-color: #d4d4d4;

		// Slide image
		&:not([data-video]) > li {
			@extend %atom-slide--full;

			& a:first-child {
				display: none;
			}

			& a {
				position: absolute;
				top: 150px;
			}
		}

		// Slide Video
		.owl-video-tn {
			@extend %atom-slide--full--video;
		}
	}

	// TEXTE DU SLIDER
	.slider-text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 90;
		text-decoration: none;
		text-align: center;
        width: 100%;
        padding-left: 100px;
		padding-right: 150px;
		
		@include RWD(mobile){
			padding: 50px;
		}
		@include RWD(tablet) {
			
		}
        
		.slider-title {
			font-size: 5.0rem;
			color: #FFFFFF;
			font-weight: 600;
			text-transform: uppercase;
			text-align: left;

			@include RWD(mobile_only) {
				font-size: 25px;
			}

			& + .slider-description {
				margin-top: 50px;
			}
		}

		.slider-description {
			font-size: 2.0rem;
			color: #FFFFFF;
			font-weight: 400;
			text-align: left;
			line-height: 30px;
			padding-left: 100px;
			padding-right: 150px;

			@include RWD(tablet){
				
			}

			@include RWD(mobile){
				padding: unset;
			}

			@include RWD(mobile_only) {
				height: 150px;
				overflow:hidden;
				&:after {
					content: '...';
					display: block;
					position: absolute;
					bottom: 50px;
					right: 50px;
				}
			}
		}
    }
    
    .slider-link--actus{
        border:1px white solid;
        width:210px;
        height:50px;
        margin-top:40px;
        display:flex;
        align-items: center;
		justify-content:center;
		position: absolute;
		bottom: 25%;
		left: 100px;
		z-index: 10;

		@include RWD(tablet){
			bottom: 20%;
		}

		@include RWD(mobile){
			bottom: 10%;
		}
        a{
            text-decoration:none;
            color:white;
            font-size:1.6rem;
        }
    }

	// NEXT/PREV ARROWS
	.owl-nav {
		justify-content: center;
		display: flex;
		z-index: 99;
		@extend %bones-slider-Ycentered-control--full;

		@include RWD(mobile) {
			display: none;
		}

		.owl-prev {
			@extend %atom-slider-arrow--full--grey; // Flèches
			//@extend %atom-slider-arrow-paged--full--grey; // Pagination X/Y
			// @extend %animation-slider-arrow-fade;
			//left: calc(100vh - (1100px / 2));
            position:absolute;
			left:0;
			
			@include RWD(tablet){
				left: 100px;
			}

			@include RWD(mobile){
				left: 20px;
			}
		}

		.owl-next {
			@extend %atom-slider-arrow--full--grey; // Flèches
			//@extend %atom-slider-arrow-paged--full--grey; // Pagination X/Y
			// @extend %animation-slider-arrow-fade;
			//right: calc(100vh - (1100px / 2));
			position:absolute;
			right:0;
			
			@include RWD(tablet){
				right: 100px;
			}

			@include RWD(mobile){
				right: 50px;
			}
		}

		&.disabled {
			display: none;
		}
	}

	// PAGERS
	.owl-dots {
		justify-content: center;
		display: flex;
		z-index: 999;
		position: absolute;
		bottom: 20%;
		transform: translateY(25%);
		left: 130px;
		
		@include RWD(tablet){
			bottom: 15%;
		}

		@include RWD(mobile) {
			display: none;
		}

		.owl-dot {
			@extend %atom-sliders-dot--full;
		}
	}

	// PlayPause
	.owl-auto {
		position: absolute;
		bottom: 20%;
		left: 100px;
		content: '';
		display: inline-block;
		width: 15px;
		height: 15px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		background-image: url(svg-bg-uri($svg-play, #089c9c));
		z-index: 10;

		@include RWD(tablet){
			bottom: 15%;
		}

		@include RWD(mobile) {
			display: none;
		}

		&.pause {
			background-image: url(svg-bg-uri($svg-pause, #089c9c));
		}
	}

	// SCROLLDOWN
	.scrollDown {
		z-index: 100;
		position: absolute;
		bottom: 0;
		left: 0;
		font-size: 1.4rem !important;
		color: #fff;
		font-weight: bold;
		display: flex;
		align-items: center;
		transform: rotate(-90deg) translate(70px, -70px);
		text-decoration: none;

		@include RWD(tablet) {
			left: 20px;
		}

		&:before {
			content: "";
			height: 2px;
			width: 100px;
			background: #fff;
			display: block;
			margin-right: 2rem;
		}
	}
}

.container-text-link-nav {
    width:100%;
    position:relative;
    display: flex;
    flex-direction: column;
}