%atom-sliders-dot--full{
    background: #fff;
    padding: 2px;
    border-radius: 50%;
    margin: 5px 7px;
    height: 15px;
    width: 15px;
    cursor: pointer;

    &.active{
        background: transparent;
        border: 1px solid #fff;
    }
    &:not(.active){
        &:hover, &:focus{
            span{
                background-color: $core-color;
            }
        }
    }
}