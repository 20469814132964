%organism-actus-home{
    @extend %bones-section-hp;
    background-color: #f8f8f8;
    .subtitle{
        color: #222222;
    }
    #actus-slider-container{
        position: relative;
        @extend %container;

        // Controls
        .owl-nav{
            justify-content: center;
            display: flex;
            @extend %bones-slider-Ycentered-control;
            z-index: 1000;
            &.disabled{
                display: none;
            }

            @media (max-width: 1280px){
                display: none;
            }
        }
        .owl-dots{
            @media (max-width: 1280px){
                display: flex;
                justify-content: center;
                margin-top: 20px;
                .owl-dot{
                    border: solid 2px $second-color;
                    height: 15px;
                    width: 15px;
                    content: '';
                    transition: all, 0.5s;
                    border-radius: 5000px;
                    margin: 0 2.5px;
                    
                    &.active{
                        background-color: $second-color;
                    }

                    &:hover{
                        background-color: $second-color;
                    }
                }
            }
        }
        .owl-prev, .owl-next{
            position: absolute;
            border: 1px solid #7a7a7a;
            color: #7a7a7a;
            height: 38px;
            width: 38px;
            border-radius: 38px;
            font-size: 30px;
            font-size: 3.0rem;
            cursor: pointer;
            transition: all, 0.5s;
            .flexbox{
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &.disabled{
                display: none;
            }
            &:hover, &:focus{
                background-color: $core-color;
                border-color: $core-color;
                color: #FFFFFF;
            }
        }
        .owl-prev{
            left: -5%;
        }
        .owl-next{
            right: -5%;
        }

        a{
            display: block;
            text-decoration: none;

            &:hover, &:focus{
                .slide-visu{
                    .slide-background, img, svg{
                        transform: scale(1.2);
                    }
                }
            }
        }
        .slide-visu{
            height: 250px;
            overflow: hidden; 
            position: relative;
            

            img, svg{
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                transition: all, 0.5s;
                width: auto;
                max-width: 100%;
            }
            .slide-background{
                background-size: cover;
                background-position: center;
                height: 100%;
                width: 100%;
                transition: all, 0.5s;
                position: absolute;
                top: 0;
                left: 0;
            }

            @include RWD(tablet){
                height: auto;
                padding-bottom: 56%;
            }
        }
        .slide-title{
            margin-top: 25px;
            @extend %text-teaser-title;
            color: #222222;

            @include RWD(mobile){
                text-align: center;
            }
        }
        .slide-description{
            margin-top: 15px;
            @extend %text-teaser-description;
            color: #5a5a5a;

            @include RWD(mobile){
                text-align: center;
            }
        }
    }
    // Animation au scroll

        @for $i from 1 through 20{
            .delay-element-#{$i}{
                transform: translateY(80px);
                opacity: 1;
                transition: all, 0.5s;
                transition-delay: #{($i - 1) * 0.2}s;
            }
        }
        .slider[data-animation-state="active"]{
            @for $i from 1 through 20{
                .delay-element-#{$i}{
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    .btn-section{
        background-color: $second-color;
        .btn-text{
            color: #FFFFFF;
        }

        &:hover, &:focus{
            background-color: $core-color;
        }
    }
}