%molecule-map-searchbox{
    height: 100%;
    background-color: #FFFFFF;
    overflow-x: hidden; 
    position: relative;
    max-width: 315px;

    #search{
        padding: 0 20px;

    }

    #edit-keys-wrapper label{
        @extend %head-map-module;
        padding: 20px 0px 10px;
        display: block;
        text-align: left;
    }

    .filter-key-container{
        position: relative;
    }

    // Bouton itinéraire
    #form-maps-button-itinerary{
        position: absolute;
        bottom: 0;
        right: 0px;
        content: '';
        display: inline-block;
        width: 40px;
        height: 35px; 
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-map-itinary, $icon-color-active) );

        &:hover, &:focus{
            background-image: url( svg-bg-uri($svg-map-itinary, $core-color) );
        }
    }

    // Proximité
    #edit-field-geofield-distance-wrapper{

        // heading recherche à proximité
        > .form-label{
            padding-top: 15px;
            border-top: solid 1px $core-color;
            margin-top: 15px;
            margin-bottom: 10px;

            label{
                padding: 0;
                display: flex;
                align-items: flex-start;
                color: $icon-color-active;
                text-align: left;
                text-transform: none;
                font-weight: 500;

                &:before{
                    content: ''; 
                    display: 'inline-block';
                    flex: 1 0 40px;
                    height: 40px;
                    background-size: 30px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url( svg-bg-uri($svg-map-flag, $icon-color-active) );
                    position: relative;
                    top: -2px;
                }
            }
        }
    }
    // Champs proximité
    #edit-field-geofield-distance{
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 15px;

        .form-item-field-geofield-distance-origin{
            margin-bottom: 10px;
        }
    }

    // Range
    #range{
        width: calc(100% - 20px);
        margin: 0 auto;
        // Labels
        .labels{
            display: block;
            position: relative;
            height: 30px;

            .currentValue{
                padding: 2px 5px;
                background-color: $second-color;
                color: #FFFFFF;
                position: absolute;
                transform: translateX(-50%);
                z-index: 2;

                &:after{
                    content: '';
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 5px 5px 0 5px;
                    border-color: $second-color transparent transparent transparent;
                }
            }
            .maxValue{
                padding: 2px 5px;
                position: absolute;
                left: 100%;
                transform: translateX(-50%);
                background-color: #f0f0f0;
                color: #707070;
            }
        }

        // Bar
        .bar{
            position: relative;
            height: 25px;
            display: flex;
            align-items: center;

            .full-bar{
                background-color: #f0f0f0;  
                width: 100%;
                height: 15px;
                position: relative;              
            }
            .progress-bar{
                background-color: $second-color;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                &:after{
                    content: '';
                    position: absolute;
                    top: -5px;
                    bottom: -5px;
                    right: -1px;
                    width: 2px;
                    background-color: $second-color;
                }
            }
            input{
                position: absolute;
                opacity: 0;
                z-index: 10;
                cursor: pointer;
                padding: 0;
            }
        }
        
        // Règles
        .ruler{
            margin-top: 3px;
            height: 10px;
            display: flex;
            justify-content: space-between;

            .step{
                content: '';
                display: block;
                flex: 1;
                border-left: solid 1px #616161;
                height: 3px;

                &.milestone{
                    height: 8px;
                }

                &:last-child{
                    border-right: solid 1px #616161;
                }
            }
        }
    }
    #edit-field-geofield-distance-distance{
        margin: 0; 
    }

    // Form Actions
    .form-actions{ 
        display: flex;
        justify-content: flex-end;

        // Submit
         [type="submit"]{
             @extend %atom-btn-int--blue;
         }
        //  Reset
        #edit-reset{
            padding: 0;
            height: 50px;
            width: 50px;
            margin-right: 10px;
            .flexbox{
                height: 50px;
            }
            .btn-text{ 
                font-size: 0;
                &:before{
                    content: '';
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    background-size: 30px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url( svg-bg-uri($svg-map-reset, #FFFFFF) );
                }
            }
        }
    }
}