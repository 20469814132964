%atom-slide-rte{
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    margin: 0 35px;

    @include RWD(mobile){
        margin: 0;
    }

    &--video{
        @extend %atom-slide-rte;
        &:before{
            content: '';
            display: block;
            position: absolute;
            top: 0; left: 0;
            width: 100%; height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
        }
 
        .owl-video-play-icon{
            background-image: url(../images/icons/player.svg);
            margin: 0;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 114px;

            @include RWD(mobile){
                height: 50px;
                width: 50px;
            }

            &:hover, &:focus{
                transform: translate(-50%, -50%) scale(1.3);
            }
        }
    }
}

%atom-slide-crossreading{
    display: flex;
    flex-direction: column;
    text-decoration: none; 
    color: inherit;
    transition: all, 0.5s;
    height: 100%;
    position: relative;
    margin-bottom: 0;


    &:hover, &:focus{
        .grid-item-background{
            transform: scale(1.2);
        }
    }

    .grid-item-visu{
        height: 100%;
        width: 100%;
        overflow: hidden;
        position: relative;
        transition: all, 0.5s;

        
        
        & > img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        & > .grid-item-background{
            background-size: cover;
            background-position: center;
            height: 100%;
            transition: all, 0.5s;


                
            &:before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(#0a0a0a, 0.5);
                z-index: 2;
            }
        }
    }

    .grid-item-text{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        padding: 20px;
        z-index: 3;
        .grid-item-title{
            font-size: 20px;
            font-size: 2.0rem;
            font-weight: 500;
            margin-bottom: 15px;
            color: #FFFFFF;
        }
        .grid-item-date{

        }
        .grid-item-categories{

        }
        .grid-item-description{
            font-size: 15px;
            font-size: 1.5rem;
            color: #e7e7e7;

            > *:last-child{
                margin-bottom: 0;
            }
        }
    }
     
}