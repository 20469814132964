/* Welcome to Compass. Use this file to define print styles.
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/print.css" media="print" rel="stylesheet" type="text/css" /> */
.print-only{
  display: none;
}
.fakeH1{
  // @extend %h1;
}
@media print {
  
  *:not(svg):not(h2):not(h1):not(h3):not(h4) {
    position: static !important;
    transform: none !important;
    height: auto !important;
    min-height: auto !important;
    width: auto !important;
    text-align: left !important;
    // padding: 0 !important;
    // margin: 0 !important;  
    // display: block !important;
  }
  div{
    margin: 0!important;
  }
  *:not(svg){
    padding: 0 !important;
    margin-left: 0!important;
    margin-right: 0!important;
    line-height: normal !important; 
    width: 100% !important;
  }
  li{
    display: block !important;
  }
  body {
    width: auto!important;
    margin: auto!important;
    font-family: serif;
    font-size: 12pt;
    background-color: #fff!important;
    color: #000!important;
  }
  h1, h2, h3, h4, h5, h6, blockquote, ul, ol {
    color: #000!important;
    margin: auto!important;
  }
  .media{
    display: block !important;
    & > * {
      display: block !important;
    }
    .media-heading{
      display: block !important;
      width: 100% !important;
    }
  }
  .printable {
    display: block; /* affichage des elements de classe printable */
  }
  .print-only{
    display: block;
  }
  p, blockquote {
    orphans: 3; /* pas de ligne seule en bas */ 
    widows: 3; /* pas de ligne seule en haut */
  }
  blockquote, ul, ol {
    page-break-inside: always; /* pas de coupure dans ces elements */
    page-break-after: avoid; /* pas de saut aprÃ¨s ces elements */
    page-break-before: avoid; /* pas de saut aprÃ¨s ces elements */
  }
  h1 {
    /*page-break-before: always;  chaque titre commence sur une nouvelle page */
  }
  h1, h2, h3, caption {
    page-break-after: avoid; /* pas de saut aprÃ¨s ces elements */
    margin: 0;
  }
  a {
    color: #000!important;
    text-decoration: underline!important;
  }
  /*Affichage des URL des liens*/
  a[href]:after {
    content: " (" attr(href) ")";
    display: block !important;
    width: 100% !important;
    text-align: left !important;
    float: none !important;
    // display: none; 
  }

  nav, menu, form, aside, button, audio, video, #menu, #main-menu{
    display: none !important;
  }  
  header{
    display: block !important;
  }
  .footer-right{
    display: none !important;
  }
  .footer-head{
    display: none !important;
  }
  .footer-body,
  footer .footer-foot ul{
    display: block !important; 
  }
  // FAQ
  .question-head, .question-body{
    display: block !important;
  }

  .grid-list-intern{
    display: block !important;
  }
  .pagination{ 
    display: none !important;
  }
  .my-account{
    display: none !important;
  }
  .e-services{
    display: block !important;
  }
  #push-actions{
    display: block !important;
  }
  #my-section{
    display: block !important;
  }
  a{
    span{
      display: none !important;
    }
  }
  .class_group_home{
    h2{
      page-break-before: always !important;
    } 
  }
  .grid-list-zoom{
    display: block !important;
  }
  .class_group_home #push-actions .push{
    display: block !important;
  }
  section{
    page-break-before: always !important;
  }
  #skipnavigation{
    display: none !important;
  }
  .fakeH1{
    font-size: 20px;
    font-size: 2.0rem;
  }
  header .social-bar{
    display: none;
  }
  .class_group_home #push-actions h2{
    display: inline-block !important;
  }
  .class_group_home #my-section .e-services .grid-list-services{
    display: block !important;
  }
  .class_group_home #my-section .e-services .grid-list-services .grid-item{
    margin-bottom: 10px !important;
  }
  .slider-agenda, .slider-next, .slider-prev,
  .class_group_home #agenda .part-newsletter
  {
    display: none !important; 
  }
  .logo-head{
    display: block !important;
    img{
      display: block !important;
      opacity: 1 !important;
    }
  }
}  