html {
  font-size: 10px;
  font-family: $site-font;

  body {
    overflow-x: hidden;
  }

  &.no-scroll {
    height: 100%;
    overflow: hidden;
  }
}
#main-content {
  overflow: hidden;
  .block-container-carrousel-dactualites-page-daccu .contextual-links-wrapper,
  .block-container-carrousel-actualite-page-accueil .contextual-links-wrapper {
    top: 200px;
  }
}
.container {
  @extend %container;
}
::selection {
  background-color: $core-color;
  color: #ffffff;
}
::-moz-selection {
  background-color: $core-color;
  color: #ffffff;
}
::-webkit-input-placeholder {
  opacity: 1 !important;
}
:-moz-placeholder {
  opacity: 1 !important;
}
::-moz-placeholder {
  opacity: 1 !important;
}
:-ms-input-placeholder {
  opacity: 1 !important;
}
#loader {
  @extend %molecule-loader;
}
#cookies {
  @extend %molecule-cookies;
}
.alert-banner .user-alert {
  @extend %molecule-cookies;
  @extend %molecule-user-alert;
}
.element-invisible,
.hidden {
  display: none;
}
header {
  @extend %organism-header-home;
}
footer {
  @extend %organism-footer;
}

// Autocomplete
.ui-autocomplete {
  background-color: #ffffff;
  z-index: 100000;
  a {
    color: $core-color;
    font-size: 13px;
    font-size: 1.3rem;
    cursor: pointer;

    strong {
      font-weight: 600;
    }
  }
}

#scroll-top {
  display: block;
  content: "";
  width: 50px;
  height: 50px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(svg-bg-uri($svg-scroll-top, #ffffff));
  background-color: $core-color;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 10;
  transform: rotate(-90deg);
  border: solid 1px #ffffff;

  @include RWD(mobile) {
    bottom: 10px;
    right: 10px;
  }
}
