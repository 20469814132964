%organism-nav-tools{
    padding-top: 5px;
    padding-bottom: 5px;
    @extend %container;
    display: flex;
    align-items: center;     
    margin-bottom: 20px;
    margin-top: 20px;

    .block-yellowstone-front-custom{
        margin-right: auto;
    }
    .page-depth{
        @extend %molecule-ariane;
    }
    .back{
        display: none;
        font-size: 16px;
        font-size: 1.6rem;
        color: #2d2d2d;
        text-decoration: none;

        &:hover, &:focus{
            text-decoration: underline;
        }

        &:before{
            content: '< ';
        }
        @include RWD(mobile){
            display: block;
        }
    }
    .print-this{
        @extend %molecule-print-this; 
    }
    .share-this{
        @extend %molecule-share-this; 
    }
}