%bones-section-hp{
    padding-top: 130px;
    padding-bottom: 100px;

    .subtitle{
        @extend %text-section-subtitle-hp;
        text-align: center;
        margin-bottom: 15px;
        color: #222222;
    }

    .title{
        @extend %text-section-title-hp;
        text-align: center;
        margin-bottom: 50px;
        color: #222222;
    }
    .section-action{
        display: flex;
        justify-content: center;
    }
    .btn-section{
        margin-top: 50px;
        transition: all, 0.5s;
        @extend %atom-btn-hp;
    }

    @include RWD(tablet){
        padding-top: 100px;
        padding-bottom: 70px;
    }

    @include RWD(mobile){
        padding-top: 50px;
        padding-bottom: 35px;
    }
}