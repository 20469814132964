/**
Animation d'apparition d'une grille au scroll: les éléments s'animent en décalé

*/

@mixin gridDelayed($child-class, $child-number, $delay){
    .#{$child-class}{
        @for $i from 1 through $child-number{
            .delay-element-#{$i}{
                transition-delay: transform #{($i) * $delay}s;
            }
        }
    }
}

// [data-scroll-animation="grid-delayed"]{
//     @for $i from 1 through 20{
//         .delayed-element-#{$i}{
//             // Etat non scrollé
//             transition: all, 0.5s;
//             transition-delay: #{($i - 1) * 0.2}s;
//         }
//     }
//     [data-animation-state="active"]{
//         .delayed-element{
//             // Etat scrollé
//         }
//     }
// }