$path: '../fonts/';

// LATOS

// Black
@font-face {
    font-family: 'Lato';
    src: url('#{$path}LatoBlack/lato-black-webfont.eot');
    src: url('#{$path}LatoBlack/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}LatoBlack/lato-black-webfont.woff2') format('woff2'),
         url('#{$path}LatoBlack/lato-black-webfont.woff') format('woff'),
         url('#{$path}LatoBlack/lato-black-webfont.ttf') format('truetype'),
         url('#{$path}LatoBlack/lato-black-webfont.otf') format('otf'),
         url('#{$path}LatoBlack/lato-black-webfont.svg#latoblack') format('svg');
    font-weight: 900; 
    font-style: normal;
}

// Heavy
@font-face {
    font-family: 'Lato';
    src: url('#{$path}LatoHeavy/lato-heavy-webfont.eot');
    src: url('#{$path}LatoHeavy/lato-heavy-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}LatoHeavy/lato-heavy-webfont.woff2') format('woff2'),
         url('#{$path}LatoHeavy/lato-heavy-webfont.woff') format('woff'),
         url('#{$path}LatoHeavy/lato-heavy-webfont.ttf') format('truetype'),
         url('#{$path}LatoHeavy/lato-heavy-webfont.otf') format('otf'),
         url('#{$path}LatoHeavy/lato-heavy-webfont.svg#latoheavy') format('svg');
    font-weight: 800;
    font-style: normal;
}

// Bold
@font-face {
    font-family: 'Lato';
    src: url('#{$path}LatoBold/lato-bold-webfont.eot');
    src: url('#{$path}LatoBold/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}LatoBold/lato-bold-webfont.woff2') format('woff2'),
         url('#{$path}LatoBold/lato-bold-webfont.woff') format('woff'),
         url('#{$path}LatoBold/lato-bold-webfont.ttf') format('truetype'),
         url('#{$path}LatoBold/lato-bold-webfont.otf') format('otf'),
         url('#{$path}LatoBold/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: 600;
    font-style: normal;
}

// Medium
@font-face {
    font-family: 'Lato';
    src: url('#{$path}LatoMedium/lato-medium-webfont.eot');
    src: url('#{$path}LatoMedium/lato-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}LatoMedium/lato-medium-webfont.woff2') format('woff2'),
         url('#{$path}LatoMedium/lato-medium-webfont.woff') format('woff'),
         url('#{$path}LatoMedium/lato-medium-webfont.ttf') format('truetype'),
         url('#{$path}LatoMedium/lato-medium-webfont.otf') format('otf'),
         url('#{$path}LatoMedium/lato-medium-webfont.svg#latomedium') format('svg');
    font-weight: 700;
    font-style: normal;
}

// Semi-Bold
@font-face {
    font-family: 'Lato';
    src: url('#{$path}LatoSemibold/lato-semibold-webfont.eot');
    src: url('#{$path}LatoSemibold/lato-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}LatoSemibold/lato-semibold-webfont.woff2') format('woff2'),
         url('#{$path}LatoSemibold/lato-semibold-webfont.woff') format('woff'),
         url('#{$path}LatoSemibold/lato-semibold-webfont.ttf') format('truetype'),
         url('#{$path}LatoSemibold/lato-semibold-webfont.otf') format('otf'),
         url('#{$path}LatoSemibold/lato-semibold-webfont.svg#latosemibold') format('svg');
    font-weight: 500;
    font-style: normal;
}

// Regular
@font-face {
    font-family: 'Lato';
    src: url('#{$path}LatoRegular/lato-regular-webfont.eot');
    src: url('#{$path}LatoRegular/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}LatoRegular/lato-regular-webfont.woff2') format('woff2'),
         url('#{$path}LatoRegular/lato-regular-webfont.woff') format('woff'),
         url('#{$path}LatoRegular/lato-regular-webfont.ttf') format('truetype'),
         url('#{$path}LatoRegular/lato-regular-webfont.otf') format('otf'),
         url('#{$path}LatoRegular/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

// Light
@font-face {
    font-family: 'Lato';
    src: url('#{$path}LatoLight/lato-light-webfont.eot');
    src: url('#{$path}LatoLight/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}LatoLight/lato-light-webfont.woff2') format('woff2'),
         url('#{$path}LatoLight/lato-light-webfont.woff') format('woff'),
         url('#{$path}LatoLight/lato-light-webfont.ttf') format('truetype'),
         url('#{$path}LatoLight/lato-light-webfont.otf') format('otf'),
         url('#{$path}LatoLight/lato-light-webfont.svg#latolight') format('svg');
    font-weight: 300;
    font-style: normal;
}