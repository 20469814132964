%molecule-link-group{
    clear: both;
    li{
        // margin-bottom: 30px;
        a{
            transition: all, 0.5s;

            &:hover, &:focus{
                background-color: #d1eead;
            }
        }
        &:nth-child(2n+1){
            a{
                background-color: #f7f7f7;
            }
        }
    } 

    .link-group-item{
        display: flex;
        align-items: center;
        @extend %atom-h3-rte;
        padding: 20px;
        text-decoration: none;
        color: #595959;
        
        span{
            display: none; 
        }
        svg{
            margin-right: 20px;
            width: 30px;
            path{
                fill: $core-color;
                transition: all, 0.5s;
            }
        }
    }
}