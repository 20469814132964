%skin-form-validation{
    .error{
        border-color: $error !important;

        & + .error{
            color: $error;
            margin-top: 5px;
        } 
    }
    .valid{
        border-color: $success !important;
    }
}