// BreakPoints
$break-xsmall: 425;
$break-small: 768;
$break-large: 1140;

// COLORS
	$core-color: #608000;
	$core-color-access: #3fa331;
	$second-color: #069e99;
	$success: $core-color;   
	$error: #d96d00;
 
// Fonts
$site-font: 'Lato';
$main-font: $site-font; 

/*IMAGES RTE*/
$image-xs: 240px;
$image-sm: 480px;
$image-md: 620px;

// Margin RTE
$margin-item-rte: 40px;
$margin-p: 20px;

$margin-h2: 25px;
$margin-h3: 25px;
$margin-h4: 20px;
$margin-h5: 15px;

$margin-h2-mobile: 20px;
$margin-h3-mobile: 20px;
$margin-h4-mobile: 15px;
$margin-h5-mobile: 10px;

// Map engine
$icon-color: #595959;
$icon-color-active: $second-color;